import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static get targets() { 
    return [ "submit", "slim" ] 
  }

  connect() {
    if (this.hasSlimTarget) {
      this.select = new SlimSelect({
        select: this.slimTarget
      })
    } 
  }

  submit() {
    this.submitTarget.click()
  }

  slimSelectSubmit(e) {
    if (e.constructor.name === "CustomEvent") {
      this.element.requestSubmit()
    }
  }

  disconnect() {
      this.select.destroy()
  }
}
